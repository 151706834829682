import { useEffect, useState, useRef, useMemo } from 'react';
import { MapContainer, TileLayer, Popup, Circle } from 'react-leaflet';
import ProfileInformation from './ProfileInformation.jsx';
import SetViewOnCoordinatesChange from './SetViewOnCoordinates.jsx';
import { v4 as uuvidv4 } from 'uuid';

const MapCircles = ({ currentCoordinates, zoom, setZoom, filterApiData }) => {
    const [circleRadius, setCricleRadius] = useState(300);
    const [prevZoom, setPrevZoom] = useState(zoom);
    const [prevCoordinates, setPrevCoordinates] = useState(currentCoordinates);
    const circleRef = useRef();

    const circles = useMemo(() => {
        if (!filterApiData) {
            return [];
        }

        return filterApiData?.map((location) => (
            location.coordinates.length === 2 ?
                <Circle
                    key={uuvidv4()}
                    center={location.coordinates}
                    radius={5}

                    pathOptions={{ fillColor: location.colour, fillOpacity: 0.5, color: location.colour }}

                >
                    <Popup key={uuvidv4()}>
                        <ProfileInformation key={uuvidv4()} shopName={location.shopName} address={location.address} service={location.services} prices={location.prices} currencies={location.currencies} geopoints={location.coordinates} />
                    </Popup>
                </Circle>
                : null
        ));
    }, [filterApiData])

    useEffect(() => {
        if (prevCoordinates !== currentCoordinates || prevZoom !== zoom) {
            if (zoom > 15) {
                setCricleRadius(5)
            } else if (zoom > 12 && circleRadius > 15) {
                setCricleRadius(20)
            } else if (zoom > 8 && zoom <= 12) {
                setCricleRadius(100)
            } else {
                setCricleRadius(300)
            }
        }
    }, [zoom, currentCoordinates])

    return (

        <MapContainer center={currentCoordinates} zoom={zoom} scrollWheelZoom={true} zoomControl={false} >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            <SetViewOnCoordinatesChange setZoom={setZoom} setCricleRadius={setCricleRadius} coordinates={currentCoordinates} circleRef={circleRef} zoom={zoom} />

            {circles}

        </MapContainer>
    )
}

export default MapCircles;
