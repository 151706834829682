import { useState } from "react";

const Legend = ({ legendItems, priceFilter, setPriceFilter }) => {
    const [legendState, setLegendState] = useState(true)

    function toggleLegend() {
        setLegendState(
            previousValue => !previousValue
        )
    }

    return (
        <div className="legend" id="legend">
            <div className="flex">
                <h4 className="justify-self-start mx-1">Legend</h4>
                <button className="justify-self-end mx-1" onClick={toggleLegend}>
                    [{
                        legendState ?
                            " - " : " + "
                    }]
                </button>
            </div>
            {
                legendState ?
                    <ul id="legend-items" className="flex flex-col my-2">
                        {
                            Object.entries(legendItems)?.map(([color, value]) => {
                                return <li key={color}>
                                    <button style={{ backgroundColor: color }} onClick={() => setPriceFilter(color)}></button>
                                    <p>{ "<" }${value}</p>
                                </li>
                            })
                        }
                        {
                            priceFilter === "all" || priceFilter === null ? null
                                : <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded" onClick={() => setPriceFilter("all")}>Show All</button>
                        }
                    </ul> : null
            }
        </div>
    )
}

export default Legend;