const SearchServices = ({ setSearchService }) => {

    return (
        <div className="searchbox mx-2" id="searchbox">
            <select type="text" id="search-state-input" className="bg-gray-50 border-0 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white" placeholder="State here" onChange={(event) => setSearchService(event.target.value)}>
                <option value="">All Services</option>
                <option value="Haircut">Haircut</option>
                <option value="Beard">Haircut with Beard</option>
            </select>
        </div>
    )
}

export default SearchServices;

//onChange={(event) => setSearchState(event.target.value)}