import service from "./ServiceRoutes";
import http from 'axios';

const get_barbers_data = () => {
    try {
        return http.get(service.get_barbers_data)
    } catch {
        console.error("ERROR")
    }
}

const Services = {
    get_barbers_data
}

export default Services;