import { useState, useEffect } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import Services from '../Services.jsx';
import Loader from './Loader.jsx';
import MapCircles from './MapWithCircles.jsx';
const _ = require('lodash');

const MyMap = ({ legendItems, priceFilter, currentCoordinates, setMaxPriceShop, setMinPriceShop, searchState, searchService, mapZoom, setZoom }) => {
    const [loader, setLoader] = useState(false);
    const [apiLocations, setAPILocations] = useState(null);
    const [filterApiData, setFilterApiData] = useState(null);
    const [circleRadius, setCricleRadius] = useState(300);
    const [zoom, setZoom2] = useState(mapZoom)

    // it ensures we should get all prices in a list after being split by , 
    // for confirmation we should get same prices and services
    const isValidPrice = (price) => price?.length > 0;

    // it has states and their respective geopoints boundaries

    const stateCoordinates = {
        "Alabama": { minLat: 30.2, maxLat: 35.0, minLng: -88.5, maxLng: -84.9 },
        "Alaska": { minLat: 54.5, maxLat: 71.5, minLng: -179.1, maxLng: -129.6 },
        "Arizona": { minLat: 31.3, maxLat: 37.0, minLng: -114.8, maxLng: -109.0 },
        "Arkansas": { minLat: 33.0, maxLat: 36.5, minLng: -94.5, maxLng: -89.6 },
        "California": { minLat: 32.5, maxLat: 42.0, minLng: -124.5, maxLng: -114.1 },
        "Colorado": { minLat: 36.9, maxLat: 41.0, minLng: -109.0, maxLng: -102.0 },
        "Connecticut": { minLat: 40.9, maxLat: 42.1, minLng: -73.7, maxLng: -71.8 },
        "Delaware": { minLat: 38.4, maxLat: 39.8, minLng: -75.8, maxLng: -74.9 },
        "Florida": { minLat: 24.4, maxLat: 31.0, minLng: -87.6, maxLng: -80.0 },
        "Georgia": { minLat: 30.4, maxLat: 35.0, minLng: -85.0, maxLng: -80.8 },
        "Hawaii": { minLat: 18.9, maxLat: 20.5, minLng: -156.0, maxLng: -154.5 },
        "Idaho": { minLat: 41.5, maxLat: 49.0, minLng: -117.0, maxLng: -111.0 },
        "Illinois": { minLat: 36.9, maxLat: 42.5, minLng: -91.5, maxLng: -87.5 },
        "Indiana": { minLat: 37.6, maxLat: 41.8, minLng: -88.0, maxLng: -84.6 },
        "Iowa": { minLat: 40.4, maxLat: 43.5, minLng: -96.6, maxLng: -90.1 },
        "Kansas": { minLat: 36.9, maxLat: 40.0, minLng: -102.0, maxLng: -94.5 },
        "Kentucky": { minLat: 36.5, maxLat: 39.2, minLng: -89.4, maxLng: -81.9 },
        "Louisiana": { minLat: 28.7, maxLat: 33.0, minLng: -94.0, maxLng: -88.8 },
        "Maine": { minLat: 42.9, maxLat: 47.5, minLng: -71.1, maxLng: -66.9 },
        "Maryland": { minLat: 37.9, maxLat: 39.7, minLng: -79.5, maxLng: -75.0 },
        "Massachusetts": { minLat: 41.2, maxLat: 42.9, minLng: -73.5, maxLng: -69.9 },
        "Michigan": { minLat: 41.7, maxLat: 48.3, minLng: -90.5, maxLng: -82.0 },
        "Minnesota": { minLat: 43.5, maxLat: 49.0, minLng: -97.2, maxLng: -89.5 },
        "Mississippi": { minLat: 30.1, maxLat: 34.5, minLng: -91.7, maxLng: -88.1 },
        "Missouri": { minLat: 35.8, maxLat: 40.6, minLng: -95.8, maxLng: -89.1 },
        "Montana": { minLat: 44.4, maxLat: 49.0, minLng: -116.0, maxLng: -104.0 },
        "Nebraska": { minLat: 39.0, maxLat: 43.0, minLng: -104.1, maxLng: -95.3 },
        "Nevada": { minLat: 35.0, maxLat: 42.0, minLng: -120.0, maxLng: -114.0 },
        "New Hampshire": { minLat: 42.7, maxLat: 45.3, minLng: -72.6, maxLng: -70.6 },
        "New Jersey": { minLat: 38.9, maxLat: 41.4, minLng: -75.6, maxLng: -73.9 },
        "New Mexico": { minLat: 31.3, maxLat: 37.0, minLng: -109.0, maxLng: -103.0 },
        "New York": { minLat: 40.5, maxLat: 45.0, minLng: -79.8, maxLng: -71.2 },
        "North Carolina": { minLat: 33.8, maxLat: 36.6, minLng: -84.3, maxLng: -75.4 },
        "North Dakota": { minLat: 45.9, maxLat: 49.0, minLng: -104.0, maxLng: -96.6 },
        "Ohio": { minLat: 38.4, maxLat: 42.0, minLng: -85.8, maxLng: -80.5 },
        "Oklahoma": { minLat: 33.6, maxLat: 37.0, minLng: -103.0, maxLng: -94.4 },
        "Oregon": { minLat: 41.9, maxLat: 46.3, minLng: -124.6, maxLng: -116.5 },
        "Pennsylvania": { minLat: 39.7, maxLat: 42.3, minLng: -80.5, maxLng: -74.7 },
        "Rhode Island": { minLat: 41.1, maxLat: 42.2, minLng: -71.9, maxLng: -71.1 },
        "South Carolina": { minLat: 32.0, maxLat: 35.2, minLng: -83.4, maxLng: -78.5 },
        "South Dakota": { minLat: 42.5, maxLat: 45.9, minLng: -104.1, maxLng: -96.5 },
        "Tennessee": { minLat: 34.9, maxLat: 36.7, minLng: -90.3, maxLng: -81.6 },
        "Texas": { minLat: 25.8, maxLat: 36.5, minLng: -106.6, maxLng: -93.5 },
        "Utah": { minLat: 36.8, maxLat: 42.0, minLng: -114.0, maxLng: -109.0 },
        "Vermont": { minLat: 42.7, maxLat: 45.0, minLng: -73.4, maxLng: -71.5 },
        "Virginia": { minLat: 36.5, maxLat: 39.5, minLng: -83.7, maxLng: -75.2 },
        "Washington": { minLat: 45.5, maxLat: 49.0, minLng: -124.8, maxLng: -116.5 },
        "West Virginia": { minLat: 37.2, maxLat: 40.6, minLng: -82.6, maxLng: -77.7 },
        "Wisconsin": { minLat: 42.5, maxLat: 47.3, minLng: -92.9, maxLng: -86.8 },
        "Wyoming": { minLat: 40.9, maxLat: 45.0, minLng: -111.1, maxLng: -104.1 },
    };


    // dictionary to get short abbrevation for given CITY
    const stateAbbreviations = {
        "Alabama": 'AL',
        "Alaska": 'AK',
        "Arizona": 'AZ',
        "Arkansas": 'AR',
        "California": 'CA',
        "Colorado": 'CO',
        "Connecticut": 'CT',
        "Delaware": 'DE',
        "Florida": 'FL',
        "Georgia": 'GA',
        "Hawaii": 'HI',
        "Idaho": 'ID',
        "Illinois": 'IL',
        "Indiana": 'IN',
        "Iowa": 'IA',
        "Kansas": 'KS',
        "Kentucky": 'KY',
        "Louisiana": 'LA',
        "Maine": 'ME',
        "Maryland": 'MD',
        "Massachusetts": 'MA',
        "Michigan": 'MI',
        "Minnesota": 'MN',
        "Mississippi": 'MS',
        "Missouri": 'MO',
        "Montana": 'MT',
        "Nebraska": 'NE',
        "Nevada": 'NV',
        "New Hampshire": 'NH',
        "New Jersey": 'NJ',
        "New Mexico": 'NM',
        "New York": 'NY',
        "North Carolina": 'NC',
        "North Dakota": 'ND',
        "Ohio": 'OH',
        "Oklahoma": 'OK',
        "Oregon": 'OR',
        "Pennsylvania": 'PA',
        "Rhode Island": 'RI',
        "South Carolina": 'SC',
        "South Dakota": 'SD',
        "Tennessee": 'TN',
        "Texas": 'TX',
        "Utah": 'UT',
        "Vermont": 'VT',
        "Virginia": 'VA',
        "Washington": 'WA',
        "West Virginia": 'WV',
        "Wisconsin": 'WI',
        "Wyoming": 'WY',
    };


    // from average price it returns a specific color
    function getColor(price) {
        if (typeof (price) !== 'number') {
            price = parseInt(price)
        }

        if (!legendItems) {
            return "#841818"
        }

        for (let key in legendItems) {
            if (parseInt(legendItems[key]) >= price) {
                return key
            }
        }

        return "#841818"
    }

    // it calculates average of prices 
    function getAvg(prices) {
        if (prices.split(",")?.length === 0) {
            return 0
        }

        const prices_numbers = prices.split(",").map(val => parseFloat(val.trim()))

        const prices_numbers_sum = prices_numbers.reduce((n1, n2) => n1 + n2, 0)

        const prices_avg = prices_numbers_sum / prices_numbers.length

        return Number(prices_avg.toFixed(4))
    }


    // it takes an object (shop) and filter its services and corresponding prices
    function filterServices(myObj, servicesToSearch) {
        if (servicesToSearch === null || servicesToSearch === undefined || servicesToSearch?.length === 0) {
            return myObj;
        }

        let tempServices = [];
        let tempPrices = [];

        myObj.services.map((service, index) => {
            if (service.toLowerCase().replace(/\s/g, "").includes(servicesToSearch.toLowerCase())) {
                tempServices.push(service);
                tempPrices.push(myObj.prices[index]);
            }
        })

        let newObj = _.cloneDeep(myObj);

        newObj.services = tempServices;
        newObj.prices = tempPrices;

        return newObj;
    }

    // It takes state name and service and update the objects
    function filterDataByStates(state, serviceToSearch = "") {
        const shortForm = stateAbbreviations[state];
        const stateGeoPoints = stateCoordinates[state]

        let stateFilteredShops = []

        if (state === null || state === "") {
            apiLocations?.map((obj, index) => {
                const servicesFilteredObj = filterServices(obj, serviceToSearch)

                if (servicesFilteredObj?.services?.length !== 0) {
                    stateFilteredShops.push(servicesFilteredObj)
                }
            })
        } else {
            apiLocations?.map((obj, index) => {
                const [lat, lng] = obj?.coordinates;
                if (lat && lng &&
                    (
                        lat >= stateGeoPoints.minLat && lat <= stateGeoPoints.maxLat
                        &&
                        lng >= stateGeoPoints.minLng && lng <= stateGeoPoints.maxLng
                    )
                ) {
                    const servicesFilteredObj = filterServices(obj, serviceToSearch)

                    if (servicesFilteredObj?.services?.length !== 0) {
                        stateFilteredShops.push(servicesFilteredObj)
                    }
                }
            })
        }

        // other filter conditions on the basis of address -- do not remove it
        // if (state === null || state === "") {
        //     apiLocations?.map((obj, index) => {
        //         const servicesFilteredObj = filterServices(obj, serviceToSearch)

        //         if (servicesFilteredObj?.services?.length !== 0) {
        //             stateFilteredShops.push(servicesFilteredObj)
        //         }
        //     })
        // } else {
        //     apiLocations?.map((obj, index) => {
        //         if (obj.address.toLowerCase().includes(state.toLowerCase())) {
        //             // Filter Services 
        //             const servicesFilteredObj = filterServices(obj, serviceToSearch)

        //             if (servicesFilteredObj?.services?.length !== 0) {
        //                 stateFilteredShops.push(servicesFilteredObj)
        //             }

        //         } else if (
        //             shortForm &&
        //             obj.address.split(" ").some(item => item === shortForm)
        //         ) {

        //             // Filter Services
        //             const servicesFilteredObj = filterServices(obj, serviceToSearch)

        //             if (servicesFilteredObj?.services?.length !== 0) {
        //                 stateFilteredShops.push(servicesFilteredObj)
        //             }
        //         }

        //         return null;
        //     })
        // }

        getMinMax(stateFilteredShops);
        setFilterApiData(stateFilteredShops);
    }

    function filterData(filter) {
        let filterShops = []
        if (filter === "all") {
            apiLocations?.map((obj, index) => {
                filterShops.push(obj)

            })
        } else {
            apiLocations?.map((obj, index) => {
                if (obj.colour === filter) {
                    filterShops.push(obj)
                }
            })
        }

        getMinMax(filterShops)
        setFilterApiData(filterShops);
    }

    function getMinMax(filterShops = filterApiData) {
        let minObj = null;
        let maxObj = null;
        filterShops?.map((obj, index) => {
            if (minObj !== null && parseFloat(minObj.average_prices) > parseFloat(obj.average_prices) && obj.coordinates?.length === 2 && obj.services.length !== 0 && obj.prices.length !== 0) {
                minObj = obj
            } else if (minObj === null) {
                minObj = obj
            }

            if (maxObj !== null && parseFloat(maxObj.average_prices) < parseFloat(obj.average_prices) && obj.coordinates?.length === 2 && obj.services.length !== 0 && obj.prices.length !== 0) {
                maxObj = obj
            } else if (maxObj === null) {
                maxObj = obj
            }
        })

        // Setting Cheap/Expensive shops 
        setMinPriceShop(minObj);
        setMaxPriceShop(maxObj)
    }

    const getData = async () => {
        setLoader(true);
        try {
            let response = await Services.get_barbers_data();
            let minObj = null;
            let maxObj = null;

            if (response.status === 200) {
                const myData = response.data.data
                let locations = [];
                myData.map((val, index) => {
                    // temporary condition db has some wrong values

                    if (val.prices.split(",").every(isValidPrice)) {
                        let obj = {
                            id: index,
                            coordinates: val.location.split(",").map(coord => parseFloat(coord.trim())),
                            shopName: val.name,
                            services: val.services.split(","),
                            prices: val.prices.split(","),
                            address: val.address,
                            currencies: val.currencies.split(","),
                            average_prices: getAvg(val.prices),
                            colour: getColor(getAvg(val.prices))
                        }

                        locations.push(obj)

                        if (minObj !== null && parseFloat(minObj.average_prices) > parseFloat(obj.average_prices) && obj.coordinates?.length === 2) {
                            minObj = obj
                        } else if (minObj === null) {
                            minObj = obj
                        }

                        if (maxObj !== null && parseFloat(maxObj.average_prices) < parseFloat(obj.average_prices) && obj.coordinates?.length === 2) {
                            maxObj = obj
                        } else if (maxObj === null) {
                            maxObj = obj
                        }
                    }

                    return null;
                })

                setAPILocations(locations);
                setFilterApiData(locations);

                setLoader(false);

                setMinPriceShop(minObj);
                setMaxPriceShop(maxObj)
            } else {
                setAPILocations(null);
                setFilterApiData(null);
            }
        } catch {
            console.error("ERROR")
            setAPILocations(null);
            setFilterApiData(null);
        }
    }

    const handleWheel = (event) => {
        setZoom(zoom - event.deltaY * 0.01);
        setCricleRadius(circleRadius - 1);
    }

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        filterData(priceFilter);
    }, [priceFilter])

    useEffect(() => {
        filterDataByStates(searchState, searchService)
    }, [searchState, searchService])

    // *********** \\

    useEffect(() => {
        setZoom2(mapZoom)
    }, [mapZoom])

    return (
        <div id='map' onWheel={handleWheel}>
            {
                loader
                    ?
                    <div>
                        <div className='absolute h-screen w-screen flex justify-center' id='loader--div'>
                            <Loader />
                        </div>
                        <MapContainer center={currentCoordinates} zoom={zoom} scrollWheelZoom={false} zoomControl={false}>
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                        </MapContainer>
                    </div>

                    :
                    <div>
                        <MapCircles setZoom={setZoom} currentCoordinates={currentCoordinates} zoom={mapZoom} filterApiData={filterApiData} shouldUpdate={(prevProps, nextProps) => prevProps.zoom !== nextProps.zoom || prevProps.currentCoordinates !== nextProps.currentCoordinates} />
                    </div>
            }

        </div>
    )
}

// onClose={() => setSelectedLocation(null)}

export default MyMap;
