import "./App.css";
import MyMap from "./Components/Map";
import Legend from "./Components/Legend";
import InfoButton from "./Components/info_button";
import { useEffect, useState } from "react";
import SearchStates from "./Components/SearchStates";
import SearchServices from "./Components/SearchServices";
import RefreshModal from "./Components/OnRefreshModal";
import { v4 as uuidv4 } from "uuid";

function App() {
  const legendItems = {
    "#448D24": 35,
    "#8FBB4F": 75,
    "#F1AC46": 100,
    "#E46F11": 140,
    "#CE1E1E": 200,
    "#841818": 250,
  };

  const [priceFilter, setPriceFilter] = useState(null);
  const [minPriceShop, setMinPriceShop] = useState(null);
  const [maxPriceShop, setMaxPriceShop] = useState(null);
  const [currentCoordinates, setCurrentCoordinates] = useState([
    33.0576132, -96.68879109999999,
  ]);
  const [searchState, setSearchState] = useState(null);
  const [searchService, setSearchService] = useState(null);
  let [zoom, setZoom] = useState(4);

  const [refreshModal, setRefreshModal] = useState(true);

  const zoomOutMap = (currZoom, targZoom, step, delay, callback) => {
    if (zoom < 4) {
      setZoom(4);
      return;
    }

    const interval = setInterval(() => {
      setZoom((prevZoom) => {
        const newZoom = prevZoom - step;
        if (newZoom <= targZoom) {
          clearInterval(interval);
          if (callback && typeof callback === "function") {
            callback();
          }
        }
        return newZoom;
      });
    }, delay);
  };

  return (
    <div className="App">
      <MyMap
        legendItems={legendItems}
        priceFilter={priceFilter}
        currentCoordinates={currentCoordinates}
        setMinPriceShop={setMinPriceShop}
        setMaxPriceShop={setMaxPriceShop}
        searchState={searchState}
        searchService={searchService}
        setZoom={setZoom}
        mapZoom={zoom}
        maxPriceShop={maxPriceShop}
        minPriceShop={minPriceShop}
      />

      <Legend
        legendItems={legendItems}
        priceFilter={priceFilter}
        setPriceFilter={setPriceFilter}
      />

      <div className="minMax--btns absolute">
        {minPriceShop != null && minPriceShop?.coordinates?.length == 2 ? (
          <InfoButton
            message={"Cheapest:"}
            shop={minPriceShop}
            setCurrentCoordinates={setCurrentCoordinates}
            setZoom={setZoom}
            currentCoordinates={currentCoordinates}
            zoom={zoom}
            zoomOutMap={zoomOutMap}
          />
        ) : null}

        {maxPriceShop != null && maxPriceShop?.coordinates?.length == 2 ? (
          <InfoButton
            message={"Expensive:"}
            shop={maxPriceShop}
            setCurrentCoordinates={setCurrentCoordinates}
            setZoom={setZoom}
            currentCoordinates={currentCoordinates}
            zoom={zoom}
            zoomOutMap={zoomOutMap}
          />
        ) : null}
      </div>

      <div className="search absolute flex" id="search">
        <SearchStates setSearchState={setSearchState} />
        <SearchServices setSearchService={setSearchService} />
      </div>

      <div className="absolute flex" id="zoom--out">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 border border-blue-700 rounded"
          onClick={() => {
            console.log(zoom);
            zoomOutMap(zoom, 4, 2, 1000, () => {
              console.log(zoom);
              zoom = 4;
            });
          }}
        >
          Zoom Out
        </button>
      </div>

      {refreshModal ? (
        <div
          className="absolute flex justify-center items-center w-full h-full"
          id="refresh--modal"
        >
          <RefreshModal setRefreshModal={setRefreshModal}/>
        </div>
      ) : null}
    </div>
  );
}

export default App;
