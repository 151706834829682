import { RotatingLines } from "react-loader-spinner";

const Loader = (props) => {
    return (
        <div className="flex justify-center items-center" id="my--spinner">
            <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={props.loader}
            />
        </div>
    );
};

export default Loader;