

const InfoButton = ({ message, shop, setCurrentCoordinates, setZoom, currentCoordinates, zoom, zoomOutMap }) => {

    const zoomInMap = (currZoom, targZoom, step, delay, callback) => {
        const interval = setInterval(() => {
            if (currZoom < targZoom) {
                setZoom(currZoom + step);
                currZoom += step;
            } else {
                clearInterval(interval);
                if (callback && typeof (callback) === "function") {

                    callback(); // Call the callback after zoomOutMap is finished
                }
            }
        }, delay);
    };

    const handleClick = () => {
        if (currentCoordinates !== shop?.coordinates) {
            zoomOutMap(zoom, 4, 2, 500, () => {
                zoom = 4;
                setCurrentCoordinates(shop?.coordinates);
                zoomInMap(zoom, 20, 2, 500, () => {
                    zoom = 20;
                });

            });
        }
    }

    return (
        <button className="minMax--btn flex items-center justify-between px-2 py-1 mx-1 my-2" onClick={handleClick}>
            <div>
                <div className="mx-2" style={{ backgroundColor: shop?.colour, width: "13px", height: "13px", borderRadius: "50%" }}></div>

            </div>

            <p>{message}</p>
            <p>${shop?.average_prices}</p>
            <p className="overflow-hidden">({shop?.shopName})</p>

        </button>
    )
}

export default InfoButton;
