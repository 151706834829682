import { useEffect } from 'react';
import { useMap, useMapEvents } from 'react-leaflet';

function SetViewOnCoordinatesChange({ setCricleRadius, coordinates, zoom }) {
    const map = useMap();

    function calculateRadiusBasedOnZoom(zoomLevel) {
        
        if (zoomLevel > 4 && zoomLevel <= 12) {
            return 300;
        } else if (zoomLevel > 12 && zoomLevel < 15) {
            return 100;
        } else if (zoomLevel >= 15 && zoomLevel <= 16) {
            return 50;
        } else if (zoomLevel >= 17) {
            return 10;
        }
    }

    useEffect(() => {
        map.setView(coordinates, zoom, { animate: true });
    }, [coordinates, zoom, map]);

    useMapEvents({
        zoomend: () => {
            // Adjust circle radius based on the current zoom level
            zoom = map.getZoom();
            if (map.getZoom() > 4) {
                const newRadius = calculateRadiusBasedOnZoom(map.getZoom());
                setCricleRadius(newRadius);
            }
        },
    });

    return null;
}

export default SetViewOnCoordinatesChange;
