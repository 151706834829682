
import { Card } from 'flowbite-react';
import { useState, useEffect } from 'react';

const ProfileInformation = (props) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    const cardStyles = {
        maxHeight: props.service.length > 4 ? "350px" : "auto",
        overflowY: props.service.length > 4 || windowWidth < 480 ? "scroll" : "hidden",

    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getDirections = (geopoints) => {
        if (geopoints.length < 2) { console.log("Failed to open map") }
        try {
            const url = `https://www.google.com/maps?q=${geopoints[0]},${geopoints[1]}`;
            window.open(url, '_blank');
        } catch {
            console.error("ERROR - loading g-map")
        }
    }

    return (
        <Card className="max-w-sm" id='profile--card' style={cardStyles}>
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 text-white">
                {
                    props.shopName.replace(/[^a-zA-Z0-9\s,.]/g, ' ')
                }
            </h5>
            <p className="font-light" id="shop-address">
                {
                    props.address.replace(/[^a-zA-Z0-9\s,.#\/\\]/g, ' ')
                }
            </p>

            <button id="get--directions--btn" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 border border-blue-700 rounded uppercase font-bold" onClick={() => getDirections(props.geopoints)}>Get Directions</button>

            <div className="services--information">
                <ul>
                    {props.service?.map((data, index) => {
                        return (
                            <li className='flex justify-between'>
                                <p className="font-medium">{data}</p>
                                <p className="w-2/4 font-medium flex justify-end">
                                    {
                                        props.currencies[index].length !== 0 ?
                                            props.currencies[index] : "$"
                                    }

                                    {
                                        props.prices[index]?.length !== 0 ? props.prices[index].replace(/([.][0]*)$/, "") : null
                                    }
                                </p>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </Card>)
}

export default ProfileInformation;