import { FaTimes } from "react-icons/fa";

const RefreshModal = ({setRefreshModal}) => {
    return (
        <div className="refresh--modal relative bg-white w-10/12 h-4/5 md:h-fit lg:h-fit md:w-3/6 lg:w-3/6 p-6 md:p-12 lg:p-12 rounded-xl overflow-y-scroll md:overflow-auto lg:overflow-auto">
            <div id="modal--cross--btn" className="absolute top-4 right-4 flex justify-end">
                <button onClick={()=>{setRefreshModal(false)}}> 
                    <FaTimes size={20}/>
                </button>
            </div>

            <div className="top-items">
                <h1 className="text-3xl uppercase text-center">Barber Charger</h1>
            </div>

            <div className=" my-4">
                <p className="font-normal text-base">Find the nearest barber shop in the U.S. by price using our map, which sources data from appointment apps. We've included prices for two basic services: 'Classic Haircut' and 'Haircut with Beard Trim.'</p>
                <p className="my-2 text-xs text-red-500">Important: Prices might be inaccurate as barber shops themselves provide their service costs. Some may include irrelevant details, like voucher services. If your barber shop isn't listed, it likely doesn't use appointment services (e.g., Booksy, theCut, etc.).</p>
            </div>

            <div>
                <p className="text-sm">For questions, please contact us at <a href="https://wisebarber.com/about-us/" className="text-blue-600" target="_blank">https://wisebarber.com/about-us/</a> </p>
            </div>
        </div>
    )
}

export default RefreshModal;
